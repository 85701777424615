<template>
  <div>
    <!-- Machines -->
    <b-row v-show="!this.activity.machines || !this.activity.machines.length" class="m-0 bg-white">
      <b-col cols="12" class="empty-attr">{{ $t("activities.path.no_machines") }}</b-col>
    </b-row>
    <b-row class="m-0 bg-white" v-for="machine in this.activity.machines" v-bind:key="machine.model" :value="machine.model">
      <b-col cols="12" class="text-infos">{{ machine.machineType_designation }} {{ machine.model }}</b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      activity: {},
    };
  },
  props:{
    activity_id: {
      type: Number,
      default: ""
    },
  },
  async mounted() {
    if(this.activity_id)
      this.activity = await this.$store.dispatch("activity/getOneActivity", { id: this.activity_id});
  },
}
</script>

<style lang="scss" scoped>

/**
 * Color text for empty list.
 */
.empty-attr {
  text-align: left;
  color: $danger-color;
  font-size: 13.5px;
  padding: 5px 8px 5px 10px;
  border-radius: 4px;
}
</style>